import React from 'react';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import { useSiteMap } from '@unisporkal/sites';
import { useLocale } from '@unisporkal/localization';
import { lazyComponent } from '@unisporkal/react-lazy-component';
import styles from './MobileAppBlock.module.scss';

const AppStoreBadge = lazyComponent((props) =>
  import(`../icons/app_store_badges/${props.locale}?icon`)
);
const PlayStoreBadge = lazyComponent((props) =>
  import(`../icons/play_store_badges/${props.locale}?icon`)
);

const MobileAppBlock = () => {
  const siteMap = useSiteMap();
  const locale = useLocale();
  const appleAriaLabel = 'Apple App Store';
  const androidAriaLabel = 'Google Play';
  const appleNavigationTracking = () => {
    const ga4Event = navigationEvent({
      link_text: appleAriaLabel,
      link_url: siteMap.mobileAppApple(),
      ui_element_location: 'footer',
      navigation_selection: 'apple-app',
    });
    ga4Track(ga4Event);
  };
  const androidNavigationTracking = () => {
    const ga4Event = navigationEvent({
      link_text: androidAriaLabel,
      link_url: siteMap.mobileAppAndroid(),
      ui_element_location: 'footer',
      navigation_selection: 'android-app',
    });
    ga4Track(ga4Event);
  };

  return (
    <div className={styles.appStores}>
      <a
        data-testid="apple-app"
        href={siteMap.mobileAppApple()}
        aria-label={appleAriaLabel}
        onClick={appleNavigationTracking}
      >
        <AppStoreBadge
          locale={locale}
          className={styles.appleApp}
        />
      </a>
      <a
        data-testid="android-app"
        href={siteMap.mobileAppAndroid()}
        aria-label={androidAriaLabel}
        onClick={androidNavigationTracking}
      >
        <PlayStoreBadge
          locale={locale}
          className={styles.androidApp}
        />
      </a>
    </div>
  );
};

export default MobileAppBlock;
