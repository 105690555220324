const LearnMoreLinks = (teamMember) => {
  const data = {
    dataTestId: 'learnMoreLinks',
    headerILoxKey: 'learn_more_header',
    links: [
      {
        dataTestId: 'PlansAndPricing',
        iloxKey: 'plans_and_pricing',
        urlFnName: 'plansAndPricing',
        dataNavId: 'nav=footer_plans_and_pricing',
        hideLink: teamMember,
      },
      {
        dataTestId: 'PromoCodes',
        iloxKey: 'istock_promo_codes',
        urlFnName: 'promoCode',
        dataNavId: 'nav=footer_promo_codes',
      },
      {
        dataTestId: 'TipsAndTricks',
        iloxKey: 'tips_and_tricks',
        urlFnName: 'istockBlog',
        dataNavId: 'nav=footer_tips_and_tricks',
      },
      {
        dataTestId: 'SearchGuide',
        iloxKey: 'istock_search_guide',
        urlFnName: 'istockSearchGuide',
        dataNavId: 'nav=footer_search_guide',
      },
      {
        dataTestId: 'StockPhotos',
        iloxKey: 'photos_ftlp',
        urlFnName: 'stockPhotos',
        dataNavId: 'nav=footer_stock_photos',
      },
      {
        dataTestId: 'StockVideos',
        iloxKey: 'videos_ftlp',
        urlFnName: 'video',
        dataNavId: 'nav=footer_stock_videos',
      },
      {
        dataTestId: 'StockIllustrations',
        iloxKey: 'illustrations_ftlp',
        urlFnName: 'illustrations',
        dataNavId: 'nav=footer_stock_illustrations',
      },
      {
        dataTestId: 'PlugingAndApps',
        iloxKey: 'plugins_and_apps',
        urlFnName: 'pluginsAndApps',
        dataNavId: 'nav=footer_plugins_and_apps',
      },
      {
        dataTestId: 'LicenseInformation',
        iloxKey: 'license_information',
        urlFnName: 'licenseInformation',
        dataNavId: 'license_information',
      },
      {
        dataTestId: 'LegalPrivacy',
        iloxKey: 'legal_privacy',
        urlFnName: 'legalAndPrivacy',
        dataNavId: 'nav=footer_legal_privacy',
      },
    ],
  };
  return data;
};

const CompanyLinks = (countryCode) => {
  const data = {
    dataTestId: 'CompanyLinks',
    headerILoxKey: 'company',
    links: [
      {
        dataTestId: 'AboutUs',
        iloxKey: 'about_us',
        urlFnName: 'aboutUs',
        dataNavId: 'nav=footer_about_us',
      },
      {
        dataTestId: 'Newsroom',
        iloxKey: 'footer.newsroom',
        urlFnName: 'press',
        dataNavId: 'nav=footer_press',
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      {
        dataTestId: 'Investor',
        textDisplay: 'Investor',
        urlFnName: 'investor',
        dataNavId: 'nav=footer_investor',
      },
      {
        dataTestId: 'Careers',
        iloxKey: 'careers',
        urlFnName: 'careers',
        dataNavId: 'nav=footer_careers',
      },
      {
        dataTestId: 'Affiliates',
        iloxKey: 'affiliates',
        urlFnName: 'affiliates',
        dataNavId: 'nav=footer_affiliates',
      },
      {
        dataTestId: 'SellStock',
        iloxKey: 'sell_stock',
        urlFnName: 'istockWorkWithUs',
        dataNavId: 'nav=footer_sell_stock',
      },
      {
        dataTestId: 'ModernSlavery',
        iloxKey: 'modern-slavery',
        urlFnName: 'modernSlavery',
        dataNavId: 'nav=footer_modern_slavery',
        hideLink: countryCode !== 'GBR',
      },
      {
        dataTestId: 'ContributorSupport',
        iloxKey: 'contributor_support',
        urlFnName: 'contributorSupport',
        dataNavId: 'nav=footer_contributor_support',
      },
    ],
  };
  return data;
};

const SupportLinks = () => {
  const links = [
    {
      dataTestId: 'ContactUs',
      iloxKey: 'contact_us',
      urlFnName: 'contactUs',
      dataNavId: 'nav=footer_contact_us',
    },
    {
      dataTestId: 'Faq',
      iloxKey: 'faq',
      urlFnName: 'faq',
      dataNavId: 'nav=footer_faq',
    },
    {
      dataTestId: 'SiteMap',
      iloxKey: 'site_map',
      urlFnName: 'sitemap',
      dataNavId: 'nav=footer_site_map',
    },
  ];

  return {
    dataTestId: 'SupportLinks',
    headerILoxKey: 'support_footer',
    links,
  };
};

const SocialMediaLinks = {
  Facebook: {
    title: 'Facebook',
    dataNavId: 'nav_Facebook',
    id: 'footer-facebook-icon',
    testId: 'fb-link',
    class: 'fb',
    urlFnName: 'facebook',
  },
  Twitter: {
    title: 'Twitter',
    dataNavId: 'nav_Twitter',
    id: 'footer-twitter-icon',
    testId: 'tw-link',
    class: 'tw',
    urlFnName: 'twitter',
  },
  Instagram: {
    title: 'Instagram',
    dataNavId: 'nav_Instagram',
    id: 'footer-instagram-icon',
    testId: 'ig-link',
    class: 'ig',
    urlFnName: 'instagram',
  },
};

export { LearnMoreLinks, CompanyLinks, SupportLinks, SocialMediaLinks };
